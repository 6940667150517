import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const MarketingScreens = {
  MARKETING: {
    PATH: '/marketing',
    TITLE: 'Marketing',
    TITLE_ALT: 'Marketing',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_SEGMENTATION: {
    PATH: '/marketing/segmentation/list',
    HEADER_NAME: 'Segmentation',
    TITLE: 'Segmentation',
    TITLE_ALT: 'Segmentation',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_SEGMENTATION: {
    PATH: '/marketing/segmentation/list/add',
    TITLE: 'Add Segmentation',
    TITLE_ALT: 'Add Segmentation',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_SEGMENTATION: {
    PATH: '/marketing/segmentation/list/edit/:id',
    TITLE: 'Edit Segmentation',
    TITLE_ALT: 'Edit Segmentation',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_SEGMENTATION: {
    PATH: '/marketing/segmentation/list/detail/:id',
    TITLE: 'Segmentation Details',
    TITLE_ALT: 'Segmentation Details',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_CAMPAIGN: {
    PATH: '/marketing/campaign/list',
    HEADER_NAME: 'Push Notification',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_CAMPAIGN: {
    PATH: '/marketing/campaign/list/add',
    TITLE: 'Add Campaign',
    TITLE_ALT: 'Add Campaign',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CAMPAIGN: {
    PATH: '/marketing/campaign/list/edit/:id',
    TITLE: 'Edit Campaign',
    TITLE_ALT: 'Edit Campaign',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CAMPAIGN: {
    PATH: '/marketing/campaign/list/detail/:id',
    TITLE: 'Campaign Details',
    TITLE_ALT: 'Campaign Details',
    PERMISSION: '',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const segmentationBreadcrumbs = generateBreadcrumb([MarketingScreens.LIST_SEGMENTATION])
const notificationBreadcrumbs = generateBreadcrumb([MarketingScreens.LIST_CAMPAIGN])

MarketingScreens.ADD_SEGMENTATION.breadcrumbs = () => segmentationBreadcrumbs
MarketingScreens.EDIT_SEGMENTATION.breadcrumbs = () => segmentationBreadcrumbs
MarketingScreens.DETAIL_SEGMENTATION.breadcrumbs = () => segmentationBreadcrumbs
MarketingScreens.ADD_CAMPAIGN.breadcrumbs = () => notificationBreadcrumbs
MarketingScreens.EDIT_CAMPAIGN.breadcrumbs = () => notificationBreadcrumbs
MarketingScreens.DETAIL_CAMPAIGN.breadcrumbs = () => notificationBreadcrumbs

export default MarketingScreens
