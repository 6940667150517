import clsx from 'clsx'
import {DetailedHTMLProps, FC, InputHTMLAttributes} from 'react'

export type VariantCheckbox = 'classic' | 'switch'

export interface FormCheckboxProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
  error?: string
  touched?: boolean
  placeholder?: string
  variant?: VariantCheckbox
  checkedValue?: boolean
  changedValue?: any
  activeValue?: string | boolean | number
  inactiveValue?: string | boolean | number
  sizebox?: SwitchSize
}

enum SwitchSizes {
  large = 'w-[52px] h-7',
  normal = 'w-9 h-5',
}

enum DotSizes {
  large = 'w-6 h-6',
  normal = 'w-4 h-4',
}

export type SwitchSize = 'large' | 'normal'

interface CheckboxItemProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string
  sizebox?: SwitchSize
}

const ClassicCheckbox: FC<CheckboxItemProps> = ({placeholder, ...rest}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>
        <div>
          <input type='checkbox' {...rest} />
        </div>
        {placeholder && (
          <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{placeholder}</div>
        )}
      </label>
    </div>
  )
}

const SwitchCheckbox: FC<CheckboxItemProps> = ({placeholder, sizebox = 'normal', ...rest}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>
        <div className='flex items-center cursor-pointer'>
          <div className='relative'>
            <input type='checkbox' className='sr-only' {...rest} />
            <div
              className={clsx('backside bg-neutral-40 rounded-full', SwitchSizes[sizebox])}
            ></div>
            <div
              className={clsx(
                'dot absolute left-[2px] top-[2px] bg-white rounded-full transition duration-200',
                DotSizes[sizebox]
              )}
            ></div>
          </div>
        </div>
        {placeholder && (
          <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{placeholder}</div>
        )}
      </label>
    </div>
  )
}

const FormCheckbox: FC<FormCheckboxProps> = ({
  className,
  error,
  touched,
  placeholder,
  variant = 'switch',
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  ...rest
}) => {
  return (
    <div className={clsx(className, 'min-h-[44px] flex items-center')}>
      {variant === 'classic' && (
        <ClassicCheckbox
          placeholder={placeholder}
          checked={checkedValue === activeValue}
          onChange={(e) => changedValue ? changedValue(e) : null}
          {...rest}
        />
      )}
      {variant === 'switch' && (
        <SwitchCheckbox
          placeholder={placeholder}
          checked={checkedValue === activeValue}
          onChange={(e) => changedValue ? changedValue(e) : null}
          {...rest}
        />
      )}

      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormCheckbox}
