import {FC, MouseEventHandler} from 'react'
import GButton from '../Button/GButton'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  loading?: boolean
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
}) => {
  return (
    <div className='flex items-center px-6 py-5 border-t border-neutral-30'>
      <GButton
        onClick={handleSuccess}
        className='mr-4 min-w-[138px]'
        type='button'
        loading={loading}
        disabled={loading}
        data-testid='success-button'
      >
        {positiveLabel ?? 'Yes'}
      </GButton>

      <GButton
        onClick={handleClose}
        type='button'
        variant='outline'
        className='min-w-[138px]'
        disabled={loading}
        data-testid='close-button'
      >
        {negativeLabel ?? 'No'}
      </GButton>
    </div>
  )
}

export default ModalFooter
