import {useEffect} from 'react'

/**
 * helper to scroll into error field
 * @param selector - css selector to focus
 */
export const forceFocusError = (selector = '.fv-plugins-message-container.text-danger') => {
  const errorElement = document.querySelector(selector) as HTMLElement
  if (errorElement) {
    errorElement.parentElement?.scrollIntoView({behavior: 'smooth', block: 'center'})
    errorElement.parentElement?.querySelector('input')?.focus({preventScroll: true})
  }
}

/**
 * call {@link forceFocusError} after delay
 * @param delay - delay in milliseconds
 * @param selector - error field css selector to focus
 * @returns promise
 */
export const forceFocusErrorTimed = (
  delay = 500,
  selector = '.fv-plugins-message-container.text-danger'
) => {
  return new Promise<void>((r) =>
    setTimeout(() => {
      forceFocusError(selector)
      r()
    }, delay)
  )
}

/**
 * effect that call {@link forceFocusError} when there are
 * validation errors after submitting form
 * @param formik - formik object
 * @param selector - field error css selector
 */
export const useFormikFocus = (
  formik: {
    isSubmitting: boolean
    isValidating: boolean
    errors: Record<any, any>
  },
  selector = '.fv-plugins-message-container.text-danger'
) => {
  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating && Object.keys(formik.errors).length > 0) {
      forceFocusError(selector)
    }
  }, [formik.errors, formik.isSubmitting, formik.isValidating, selector])
}
