import {FC, useEffect, useState} from 'react'
import GIcon from '../Libs/Icon/GIcon'

const ScrollToTop: FC = () => {
  const [showTopBtn, setShowTopBtn] = useState<boolean>(false)

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true)
      } else {
        setShowTopBtn(false)
      }
    })
  }, [])

  return (
    <>
      {showTopBtn && (
        <div
          onClick={goToTop}
          className='fixed bottom-[40px] right-[20px] flex justify-center items-center w-9 h-9 rounded-lg bg-primary/20 text-white cursor-pointer hover:bg-primary/50 transition-all'
        >
          <GIcon icon='IconArrowTop' className='' />
        </div>
      )}
    </>
  )
}

export default ScrollToTop
