import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const ManageBannerScreens = {
  MANAGE_BANNER: {
    PATH: '/manage-banner',
    TITLE: 'Manage Banner',
    TITLE_ALT: 'Manage Banner',
    PERMISSION: 'category.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_CATEGORY: {
    PATH: '/manage-banner/category/list',
    HEADER_NAME: 'Banner Category',
    TITLE: 'Banner Category',
    TITLE_ALT: 'Category',
    PERMISSION: 'category.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_CATEGORY: {
    PATH: '/manage-banner/category/list/add',
    TITLE: 'Add Category',
    TITLE_ALT: 'Add Category',
    PERMISSION: 'category.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_CATEGORY: {
    PATH: '/manage-banner/category/list/edit/:id',
    TITLE: 'Edit Category',
    TITLE_ALT: 'Edit Category',
    PERMISSION: 'category.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_CATEGORY: {
    PATH: '/manage-banner/category/list/detail/:id',
    TITLE: 'Category Details',
    TITLE_ALT: 'Category Details',
    PERMISSION: 'category.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  LIST_BANNER: {
    PATH: '/manage-banner/banner/list',
    HEADER_NAME: 'Banner List',
    TITLE: 'Banner List',
    TITLE_ALT: 'Banner List',
    PERMISSION: 'banner.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_BANNER: {
    PATH: '/manage-banner/banner/list/add',
    TITLE: 'Add Banner List',
    TITLE_ALT: 'Add Banner List',
    PERMISSION: 'banner.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_BANNER: {
    PATH: '/manage-banner/banner/list/edit/:id',
    TITLE: 'Edit Banner List',
    TITLE_ALT: 'Edit Banner List',
    PERMISSION: 'banner.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_BANNER: {
    PATH: '/manage-banner/banner/list/detail/:id',
    TITLE: 'Banner List Details',
    TITLE_ALT: 'Banner List Details',
    PERMISSION: 'banner.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const categoryBreadcrumbs = generateBreadcrumb([ManageBannerScreens.LIST_CATEGORY])
const bannerBreadcrumbs = generateBreadcrumb([ManageBannerScreens.LIST_BANNER])

ManageBannerScreens.ADD_CATEGORY.breadcrumbs = () => categoryBreadcrumbs
ManageBannerScreens.EDIT_CATEGORY.breadcrumbs = () => categoryBreadcrumbs
ManageBannerScreens.DETAIL_CATEGORY.breadcrumbs = () => categoryBreadcrumbs
ManageBannerScreens.ADD_BANNER.breadcrumbs = () => bannerBreadcrumbs
ManageBannerScreens.DETAIL_BANNER.breadcrumbs = () => bannerBreadcrumbs
ManageBannerScreens.EDIT_BANNER.breadcrumbs = () => bannerBreadcrumbs

export default ManageBannerScreens
