import React from 'react'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  return (
    <div className='flex items-center justify-center w-screen h-screen py-24 bg-neutral-10'>
      <div className='h-max-[688px] w-[540px]'>
        <div className='flex items-center justify-between h-full py-16 rounded-lg card'>
          <img src={toAbsoluteUrl('/logo_ait.svg')} alt='AIT' className='h-20 mb-16' />

          <div className='w-[356px]'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default GuestWrapper
