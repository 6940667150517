import clsx from 'clsx'
import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useMasterLayout} from 'src/app/context/MasterContext'
import CaseManagementScreens from 'src/app/modules/case-management/Screens'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
// import HomeMenuScreens from 'src/app/modules/home-menu/Screens'
// import ManageBannerScreens from 'src/app/modules/manage-banner/Screens'
// import MarketingScreens from 'src/app/modules/marketing/Screens'
import MemberListScreens from 'src/app/modules/member-list/Screens'
import UserRoleScreens from 'src/app/modules/permissions/Screens'
import {ModulePermissionQuery} from 'src/app/modules/permissions/models/Role.model'
import {User} from 'src/app/modules/permissions/models/User.model'
import PointsEarningScreens from 'src/app/modules/points-earning/Screens'
import PointsRedemptionScreens from 'src/app/modules/points-redemption/Screens'
import TierBenefitScreens from 'src/app/modules/tier-benefit/Screens'
// import {ModulesComponentScreens, ModulesComponentsRender} from 'src/app/modules_component/Screens'
import PointExpirationScreens from 'src/app/modules/point-expiration/Screens'
import {RootState} from 'src/setup'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
}

const useMenus = (user?: User): Menu[] => {
  return useMemo(
    () =>
      [
        {
          // title: 'Building Blocks',
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: UserRoleScreens.USER_ROLE.PATH,
              icon: '/media/icons/IconUser.svg',
              title: UserRoleScreens.USER_ROLE.TITLE,
              children: [
                {
                  to: UserRoleScreens.LIST_USER.PATH,
                  activePath: UserRoleScreens.LIST_USER.PATH,
                  title: UserRoleScreens.LIST_USER.TITLE,
                  hasBullet: true,
                  module_permissions: UserRoleScreens.LIST_USER.PERMISSION,
                },
                {
                  to: UserRoleScreens.LIST_ROLE.PATH,
                  activePath: UserRoleScreens.LIST_ROLE.PATH,
                  title: UserRoleScreens.LIST_ROLE.TITLE,
                  hasBullet: true,
                  module_permissions: UserRoleScreens.LIST_ROLE.PERMISSION,
                },
              ],
            },
            // {
            //   to: HomeMenuScreens.HOMEMENU.PATH,
            //   icon: '/media/icons/IconHomeMenu.svg',
            //   title: HomeMenuScreens.HOMEMENU.TITLE,
            //   module_permissions: HomeMenuScreens.HOMEMENU.PERMISSION,
            // },
            // {
            //   to: ManageBannerScreens.MANAGE_BANNER.PATH,
            //   icon: '/media/icons/IconManageBanner.svg',
            //   title: ManageBannerScreens.MANAGE_BANNER.TITLE,
            //   children: [
            //     {
            //       to: ManageBannerScreens.LIST_CATEGORY.PATH,
            //       activePath: ManageBannerScreens.LIST_CATEGORY.PATH,
            //       title: ManageBannerScreens.LIST_CATEGORY.TITLE,
            //       hasBullet: true,
            //     },
            //     {
            //       to: ManageBannerScreens.LIST_BANNER.PATH,
            //       activePath: ManageBannerScreens.LIST_BANNER.PATH,
            //       title: ManageBannerScreens.LIST_BANNER.TITLE,
            //       hasBullet: true,
            //     },
            //   ],
            // },
            // {
            //   to: MarketingScreens.MARKETING.PATH,
            //   icon: '/media/icons/IconMarketing.svg',
            //   title: MarketingScreens.MARKETING.TITLE,
            //   children: [
            //     {
            //       to: MarketingScreens.LIST_SEGMENTATION.PATH,
            //       activePath: MarketingScreens.LIST_SEGMENTATION.PATH,
            //       title: MarketingScreens.LIST_SEGMENTATION.TITLE,
            //       hasBullet: true,
            //     },
            //     {
            //       to: MarketingScreens.LIST_CAMPAIGN.PATH,
            //       activePath: MarketingScreens.LIST_CAMPAIGN.PATH,
            //       title: MarketingScreens.LIST_CAMPAIGN.HEADER_NAME,
            //       hasBullet: true,
            //     },
            //   ],
            // },
            {
              to: MemberListScreens.LIST_MEMBER.PATH,
              icon: '/media/icons/IconUserFriends.svg',
              title: MemberListScreens.LIST_MEMBER.TITLE,
              module_permissions: MemberListScreens.LIST_MEMBER.PERMISSION,
            },
            {
              to: PointsRedemptionScreens.POINT_REDEMPTION.PATH,
              icon: '/media/icons/IconPointsRedemption.svg',
              title: PointsRedemptionScreens.POINT_REDEMPTION.TITLE,
              children: [
                {
                  to: PointsRedemptionScreens.LIST_CATALOG_REDEMPTION.PATH,
                  activePath: PointsRedemptionScreens.LIST_CATALOG_REDEMPTION.PATH,
                  title: PointsRedemptionScreens.LIST_CATALOG_REDEMPTION.TITLE,
                  module_permissions: PointsRedemptionScreens.LIST_CATALOG_REDEMPTION.PERMISSION,
                  hasBullet: true,
                },
              ],
            },
            {
              to: PointsEarningScreens.POINTS_EARNING.PATH,
              icon: '/media/icons/IconPointsEarning.svg',
              title: PointsEarningScreens.POINTS_EARNING.TITLE,
              children: [
                {
                  to: PointsEarningScreens.LIST_EARNING_RESERVE.PATH,
                  activePath: PointsEarningScreens.LIST_EARNING_RESERVE.PATH,
                  title: PointsEarningScreens.LIST_EARNING_RESERVE.TITLE_ALT,
                  module_permissions: PointsEarningScreens.LIST_EARNING_RESERVE.PERMISSION,
                  hasBullet: true,
                },
                {
                  to: PointsEarningScreens.LIST_ADDITIONAL_POINTS.PATH,
                  activePath: PointsEarningScreens.LIST_ADDITIONAL_POINTS.PATH,
                  title: PointsEarningScreens.LIST_ADDITIONAL_POINTS.HEADER_NAME,
                  module_permissions: PointsEarningScreens.LIST_ADDITIONAL_POINTS.PERMISSION,
                  hasBullet: true,
                },
                {
                  to: PointsEarningScreens.LIST_GRADUATION_POINTS.PATH,
                  activePath: PointsEarningScreens.LIST_GRADUATION_POINTS.PATH,
                  title: PointsEarningScreens.LIST_GRADUATION_POINTS.HEADER_NAME,
                  module_permissions: PointsEarningScreens.LIST_GRADUATION_POINTS.PERMISSION,
                  hasBullet: true,
                },
                {
                  to: PointsEarningScreens.LIST_EARNING_CAMPAIGN.PATH,
                  activePath: PointsEarningScreens.LIST_EARNING_CAMPAIGN.PATH,
                  title: PointsEarningScreens.LIST_EARNING_CAMPAIGN.TITLE_ALT,
                  module_permissions: PointsEarningScreens.LIST_EARNING_CAMPAIGN.PERMISSION,
                  hasBullet: true,
                },
              ],
            },
            {
              to: PointExpirationScreens.DETAIL_POINT_EXPIRATION.PATH,
              icon: '/media/icons/IconPointExpiration.svg',
              title: PointExpirationScreens.DETAIL_POINT_EXPIRATION.TITLE,
              module_permissions: PointExpirationScreens.DETAIL_POINT_EXPIRATION.PERMISSION,
            },
            {
              to: TierBenefitScreens.TIER_BENEFIT.PATH,
              icon: '/media/icons/IconTierBenefit.svg',
              title: TierBenefitScreens.TIER_BENEFIT.TITLE,
              children: [
                {
                  to: TierBenefitScreens.LIST_QUALIFICATION.PATH,
                  activePath: TierBenefitScreens.LIST_QUALIFICATION.PATH,
                  title: TierBenefitScreens.LIST_QUALIFICATION.TITLE,
                  hasBullet: true,
                  module_permissions: TierBenefitScreens.LIST_QUALIFICATION.PERMISSION,
                },
                {
                  to: TierBenefitScreens.LIST_TIER_MULTIPLIER.PATH,
                  activePath: TierBenefitScreens.LIST_TIER_MULTIPLIER.PATH,
                  title: TierBenefitScreens.LIST_TIER_MULTIPLIER.HEADER_NAME,
                  hasBullet: true,
                  module_permissions: TierBenefitScreens.LIST_TIER_MULTIPLIER.PERMISSION,
                },
                {
                  to: TierBenefitScreens.LIST_TIER_BENEFIT.PATH,
                  activePath: TierBenefitScreens.LIST_TIER_BENEFIT.PATH,
                  title: TierBenefitScreens.LIST_TIER_BENEFIT.HEADER_NAME,
                  hasBullet: true,
                  module_permissions: TierBenefitScreens.LIST_TIER_BENEFIT.PERMISSION,
                },
              ],
            },
            {
              to: CaseManagementScreens.CASE_MANAGEMENT.PATH,
              icon: '/media/icons/IconCaseManagement.svg',
              title: CaseManagementScreens.CASE_MANAGEMENT.TITLE,
              children: [
                {
                  to: CaseManagementScreens.LIST_REINSTATEMENT.PATH,
                  activePath: CaseManagementScreens.LIST_REINSTATEMENT.PATH,
                  title: CaseManagementScreens.LIST_REINSTATEMENT.TITLE,
                  hasBullet: true,
                  module_permissions: CaseManagementScreens.LIST_REINSTATEMENT.PERMISSION,
                },
              ],
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User
  const menus = useMenus(user)
  // const {hasAccess} = usePermissions()

  // const generated = useMemo(
  //   () =>
  //     filterMenus(menus, (menu) => hasAccess(menu?.module_permissions) && !Boolean(menu.hidden)),
  //   [menus, hasAccess]
  // )

  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
