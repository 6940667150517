import clsx from 'clsx'
import {FC, MouseEventHandler, ReactNode, useCallback, useEffect, useState} from 'react'
import ClientPortal from 'src/app/hooks/portal-hook'

interface GModalProps {
  children: ReactNode
  show: boolean
  onHide: MouseEventHandler
  size?: 'xl' | 'lg' | 'md' | 'sm'
}

const GModal: FC<GModalProps> = ({children, show, onHide, size = 'md'}) => {
  const [animateClose, setAnimateClose] = useState<boolean>(false)
  const [temporaryShow, setTemporaryShow] = useState<boolean>(false)

  const handleAnimateClose = useCallback((e?: any) => {
    setAnimateClose(true)
    setTimeout(() => {
      setAnimateClose(false)
      setTemporaryShow(false)
    }, 300)
  }, [])

  useEffect(() => {
    const closeOnEscapeKey = (e: any) => (e.key === 'Escape' ? handleAnimateClose() : null)
    document.body.addEventListener('keydown', closeOnEscapeKey)
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey)
  }, [handleAnimateClose])

  useEffect(() => {
    if (!show) return handleAnimateClose()
    else return setTemporaryShow(true)
    //eslint-disable-next-line
  }, [show])

  if (!temporaryShow) return null

  return (
    <ClientPortal wrapperId='modal-portal'>
      <div
        data-testid='gmodal-test'
        className='fixed top-0 right-0 left-0 bottom-0 z-[9999] flex items-center justify-center'
      >
        <div
          data-testid='gmodal-test-backdrop'
          onClick={onHide}
          className='absolute top-0 bottom-0 left-0 right-0 bg-black/50'
        ></div>
        <div className='flex items-center justify-center'>
          <div
            className={clsx(
              'card rounded-lg',
              'animate-modal-open',
              {'animate-modal-close': animateClose},
              {'w-[400px]': size === 'sm'},
              {'w-[600px]': size === 'md'},
              {'w-[720px]': size === 'lg'},
              {'w-[900px]': size === 'xl'}
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </ClientPortal>
  )
}
export default GModal
