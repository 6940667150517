import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const PointsRedemptionScreens = {
  POINT_REDEMPTION: {
    PATH: '/points-redemption',
    TITLE: 'Points Redemption',
    TITLE_ALT: 'Points Redemption',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'catalog_redemption.read',
  },
  // POINT REDEMPTION
  LIST_CATALOG_REDEMPTION: {
    PATH: '/points-redemption/catalog-redemption/list',
    HEADER_NAME: 'Catalog Redemption',
    TITLE: 'Catalog Redemption',
    TITLE_ALT: 'Catalog Redemption',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'catalog_redemption.read',
  },
  ADD_CATALOG_REDEMPTION: {
    PATH: '/points-redemption/catalog-redemption/list/add',
    TITLE: 'Add Catalog Redemption',
    TITLE_ALT: 'Add Catalog Redemption',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'catalog_redemption.create',
  },
  EDIT_CATALOG_REDEMPTION: {
    PATH: '/points-redemption/catalog-redemption/list/edit/:id',
    TITLE: 'Edit Catalog Redemption',
    TITLE_ALT: 'Edit Catalog Redemption',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'catalog_redemption.update',
  },
  DETAIL_CATALOG_REDEMPTION: {
    PATH: '/points-redemption/catalog-redemption/list/detail/:id',
    TITLE: 'Catalog Redemption Details',
    TITLE_ALT: 'Catalog Redemption Details',
    breadcrumbs: defaultBreadcrumbs,
    PERMISSION: 'catalog_redemption.read',
  },
}

export const catalogRedemptionBreadcrumbs = generateBreadcrumb([
  PointsRedemptionScreens.LIST_CATALOG_REDEMPTION,
])

PointsRedemptionScreens.ADD_CATALOG_REDEMPTION.breadcrumbs = () => catalogRedemptionBreadcrumbs
PointsRedemptionScreens.EDIT_CATALOG_REDEMPTION.breadcrumbs = () => catalogRedemptionBreadcrumbs
PointsRedemptionScreens.DETAIL_CATALOG_REDEMPTION.breadcrumbs = () => catalogRedemptionBreadcrumbs

export default PointsRedemptionScreens
