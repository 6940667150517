import clsx from 'clsx'
import {DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode} from 'react'
import GIcon, {GIconProps} from '../../Icon/GIcon'

export interface FormTextProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string
  inputClassName?: string
  error?: string
  touched?: boolean
  type?: 'text' | 'email' | 'password'
  //ICON
  prependIcon?: GIconProps
  prependInnerIcon?: GIconProps
  appendIcon?: GIconProps
  appendInnerIcon?: GIconProps
  prependClassname?: string
  prependInnerClassname?: string
  appendClassname?: string
  appendInnerClassname?: string
  clickAppendIcon?: () => void
  clickAppendInnerIcon?: () => void
  clickPrependIcon?: () => void
  clickPrependInnerIcon?: () => void
  //RENDER NODE
  prependNode?: ReactNode
  appendNode?: ReactNode
  //FIXED STRING
  prefix?: string
  suffix?: string
}

const FormText: FC<FormTextProps> = ({
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  prefix,
  suffix,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className='relative flex items-center'>
        {prependNode && <div className={clsx('ml-2')}>{prependNode}</div>}
        {prependIcon && (
          <GIcon
            icon={prependIcon}
            className={clsx('cursor-pointer mr-2 w-5 h-5', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}
        <div className='relative flex items-center flex-grow'>
          {prependInnerIcon && (
            <GIcon
              icon={prependInnerIcon}
              className={clsx('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute w-5 h-5 font-medium left-4 text-fs-9'
            >
              {prefix}
            </div>
          )}
          <input
            type={type}
            className={clsx(
              {'form-invalid': touched && error},
              {'pl-14': prependInnerIcon || prefix},
              {'pr-14': appendInnerIcon || suffix},
              `${inputClassName}`
            )}
            {...rest}
          />
          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute w-5 h-5 font-medium right-4 text-fs-9'
            >
              {suffix}
            </div>
          )}
          {appendInnerIcon && (
            <GIcon
              icon={appendInnerIcon}
              className={clsx('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}
        </div>
        {appendIcon && (
          <GIcon
            icon={appendIcon}
            className={clsx('cursor-pointer ml-2 w-5 h-5', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={clsx('ml-2')}>{appendNode}</div>}
      </div>
      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </div>
  )
}

export {FormText}
