import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import GAlert from 'src/app/components/Libs/Alert/GAlert'
import GButton from 'src/app/components/Libs/Button/GButton'
import {FormLabel, FormText} from 'src/app/components/Libs/Form/Collection'
import * as Yup from 'yup'
import {forgotPassword} from '../../../permissions/services/Permission.services'
import AuthScreens from '../../Screens'

const ForgotPassword: FC = () => {
  const [isSuccess, setIsSuccess] = useState(false)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Make sure the email address you entered is correct.')
      .required('Enter your email address'),
  })

  const initialValues = {
    email: '',
  }

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return forgotPassword(payload)
    },
    onSuccess: () => {
      setIsSuccess(true)
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setStatus("We don't recognize that Email Address. Please try again.")
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <>
      <div className='w-full'>
        {isSuccess ? (
          <>
            <div className='mb-12 text-center'>
              <div className='mb-4 font-bold text-fs-5'>Email dikirim!</div>
              <div className='mb-4 text-neutral-80'>
                Silahkan cek inbox email anda untuk melakukan ganti password.
              </div>
              <GButton href={AuthScreens.LOGIN_EMAIL.PATH}>Kembali ke halaman Login</GButton>
            </div>
          </>
        ) : (
          <>
            <div className='mb-12 text-center'>
              <div className='mb-4 font-bold text-fs-5'>Lupa Password?</div>
              <div className='text-neutral-80'>
                Masukkan email yang sudah terdaftar untuk mendapatkan instruksi untuk ganti
                password.
              </div>
            </div>

            {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

            <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
              <div className='mb-8'>
                <FormLabel required className='mb-2'>
                  Email
                </FormLabel>
                <FormText
                  {...formik.getFieldProps('email')}
                  name='email'
                  type='email'
                  placeholder='Email address'
                  maxLength={50}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                />
              </div>

              <div className='flex items-center justify-between gap-4'>
                <Link
                  to={AuthScreens.LOGIN_EMAIL.PATH}
                  className='bg-white border border-primary flex-1 py-[10px] px-[20px] rounded-lg text-black font-semibold hover:bg-primary-light-2 transition text-center'
                >
                  Kembali
                </Link>

                <GButton
                  type='submit'
                  size='large'
                  className='flex-1'
                  loading={submitFn.isLoading}
                  disabled={submitFn.isLoading}
                >
                  Kirim Email
                </GButton>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}

export default ForgotPassword
