import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import GButton from 'src/app/components/Libs/Button/GButton'
import {FormLabel, FormPassword} from 'src/app/components/Libs/Form/Collection'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {useOnline} from 'src/app/hooks/online-hook'
import {useWebTitle} from 'src/app/hooks/title-hook'
import {getErrorMessage} from 'src/app/utils/api-utils'
import * as Yup from 'yup'
import {
  resetPasswordUser,
  verificationUserToken,
} from '../../../permissions/services/Permission.services'
import AuthScreens from '../../Screens'

const CreateNewPassword: FC = () => {
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  const {addPageToasts} = useHeaderToast()
  const {isOnline, errorOffline} = useOnline()
  useWebTitle('Buat Password Baru')

  const [generatedToken, setGeneratedToken] = useState<string>('')

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .max(15, 'Password must be at most 15 characters')
      .matches(/^(?=.*[A-Z])(?=.*[!@#$&*%^()_-])(?=.*[a-z])(?=.*\d).{8,}$/, () => (
        <>
          <div>Password harus terdiri dari:</div>
          <ul>
            <li>Setidaknya 8 karakter,</li>
            <li>Terdapat huruf & angka,</li>
            <li>Terdapat huruf besar dan huruf kecil, dan</li>
            <li>Terdapat special character (!@#$&*%^()_-)</li>
          </ul>
        </>
      ))
      .required('Enter your password'),
    new_password: Yup.string()
      .min(8, 'Password minimal 8 karakter')
      .required('Enter your confirmation password')
      .max(15, 'Confirm new password must be at most 15 characters')
      .test('equal', 'Doesn’t match with new password.', function (v) {
        // Don't use arrow functions
        const ref = Yup.ref('password')
        return v === this.resolve(ref)
      }),
  })

  const initialValues = {
    password: '',
    new_password: '',
  }

  const errorState = useCallback(
    (e: unknown) => {
      if (isOnline) addPageToasts({scheme: 'danger', text: getErrorMessage(e, true)})
      else addPageToasts({scheme: 'danger', text: errorOffline})
    },
    [addPageToasts, errorOffline, isOnline]
  )

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const val = {
        new_password: payload.new_password,
      }

      return resetPasswordUser(val, {Authorization: `Bearer ${generatedToken}`})
    },
    onSuccess: () => {
      formik.setSubmitting(false)
      history.replace(AuthScreens.LOGIN_EMAIL.PATH)
    },
    onError: (e) => errorState(e),
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: resetSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  useEffect(() => {
    const payload = {
      token: token,
    }

    if (token) {
      verificationUserToken(payload)
        .then((res) => {
          const data = res?.data?.response_output?.detail?.token
          if (data === null) {
            history.replace(AuthScreens.LOGIN_EMAIL.PATH)
          }
          setGeneratedToken(data)
        })
        .catch(() => {
          history.replace(AuthScreens.LOGIN_EMAIL.PATH)
        })
    }
  }, [token, history])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  if (!token) return null

  return (
    <>
      <div className='w-full'>
        <div className='mb-12 text-center'>
          <div className='mb-4 font-medium text-fs-5'>Update Password</div>
          <div className='text-neutral-80'>Create a new password below</div>
        </div>

        <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              New Password
            </FormLabel>
            <FormPassword
              {...formik.getFieldProps('password')}
              name='password'
              placeholder='New Password'
              maxLength={50}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>

          <div className='mb-8'>
            <FormLabel required className='mb-2'>
              Confirm New Password
            </FormLabel>
            <FormPassword
              {...formik.getFieldProps('new_password')}
              name='new_password'
              placeholder='Confirm New Password'
              maxLength={50}
              error={formik.errors.new_password}
              touched={formik.touched.new_password}
            />
          </div>

          <GButton type='submit' size='large' className='w-full' loading={submitFn.isLoading}>
            Update Password
          </GButton>
        </form>
      </div>
    </>
  )
}

export default CreateNewPassword
