import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const TierBenefitScreens = {
  TIER_BENEFIT: {
    PATH: '/tier-benefit',
    TITLE: 'Tier & Benefit',
    TITLE_ALT: 'Tier & Benefit',
    breadcrumbs: defaultBreadcrumbs,
  },
  // QUALIFICATION CRITERIA
  LIST_QUALIFICATION: {
    PATH: '/tier-benefit/qualification_criteria/list',
    HEADER_NAME: 'Qualification Criteria',
    TITLE: 'Qualification Criteria',
    TITLE_ALT: 'Qualification Criteria',
    PERMISSION: 'qualification_criteria.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_QUALIFICATION: {
    PATH: '/tier-benefit/qualification_criteria/list/add',
    TITLE: 'Add Qualification Criteria',
    TITLE_ALT: 'Add Qualification Criteria',
    PERMISSION: 'qualification_criteria.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_QUALIFICATION: {
    PATH: '/tier-benefit/qualification_criteria/list/edit/:id',
    TITLE: 'Edit Qualification Criteria',
    TITLE_ALT: 'Edit Qualification Criteria',
    PERMISSION: 'qualification_criteria.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_QUALIFICATION: {
    PATH: '/tier-benefit/qualification_criteria/list/detail/:id',
    TITLE: 'Qualification Criteria Details',
    TITLE_ALT: 'Qualification Criteria Details',
    PERMISSION: 'qualification_criteria.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // TIER MULTIPLIER
  LIST_TIER_MULTIPLIER: {
    PATH: '/tier-benefit/tier-multiplier/list',
    HEADER_NAME: 'Tier Multiplier',
    TITLE: 'Tier Multiplier',
    TITLE_ALT: 'Earning & Reserve Rate',
    PERMISSION: 'multipliers.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TIER_MULTIPLIER: {
    PATH: '/tier-benefit/tier-multiplier/list/edit',
    TITLE: 'Edit Tier Multiplier',
    TITLE_ALT: 'Edit Earning & Reserve Rate',
    PERMISSION: 'multipliers.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_TIER_MULTIPLIER: {
    PATH: '/tier-benefit/tier-multiplier/list/detail/:id',
    TITLE: 'Tier Multiplier Details',
    TITLE_ALT: 'Earning & Reserve Rate Details',
    PERMISSION: 'multipliers.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // TIER BENEFIT
  LIST_TIER_BENEFIT: {
    PATH: '/tier-benefit/benefit/list',
    ACTIVE_PATH: '/tier-benefit/benefit',
    HEADER_NAME: 'Tiers Benefit',
    TITLE: 'Tiers Benefit',
    TITLE_ALT: 'Tiers Benefit',
    PERMISSION: 'tier_benefit.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_TIER_BENEFIT: {
    PATH: '/tier-benefit/benefit/list/add',
    TITLE: 'Add Tiers Benefit',
    TITLE_ALT: 'Add Tiers Benefit',
    PERMISSION: 'tier_benefit.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TIER_BENEFIT: {
    PATH: '/tier-benefit/benefit/list/edit/:id',
    TITLE: 'Edit Tiers Benefit',
    TITLE_ALT: 'Edit Tiers Benefit',
    PERMISSION: 'tier_benefit.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_TIER_BENEFIT: {
    PATH: '/tier-benefit/benefit/list/detail/:id',
    TITLE: 'Tiers Benefit Details',
    TITLE_ALT: 'Tiers Benefit Details',
    PERMISSION: 'tier_benefit.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default TierBenefitScreens
