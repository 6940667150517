import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const PointsExpirationScreens = {
  POINT_EXPIRATION: {
    PATH: '/point-expiration',
    TITLE: 'Point Expiration',
    TITLE_ALT: 'Point Expiration',
    breadcrumbs: defaultBreadcrumbs,
  },
  // POINT EXPIRATION
  DETAIL_POINT_EXPIRATION: {
    PATH: '/point-expiration',
    TITLE: 'Point Expiration',
    TITLE_ALT: 'Point Expiration',
    PERMISSION: 'point_expiration.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_POINT_EXPIRATION: {
    PATH: '/point-expiration/edit',
    TITLE: 'Edit Point Expiration',
    TITLE_ALT: 'Edit Point Expiration',
    PERMISSION: 'point_expiration.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default PointsExpirationScreens
