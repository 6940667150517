const ModulesComponentScreens = {
  MODULES_COMPONENT: {
    PATH: '/components',
    TITLE: 'Modules Components',
    TITLE_ALT: 'Components',
  },
  ALERT_COMPONENT: {
    PATH: '/components/alert-component',
    TITLE: 'Alert',
    TITLE_ALT: 'Alert',
  },
  BREADCRUMB_COMPONENT: {
    PATH: '/components/breadcrumb-component',
    TITLE: 'Breadcrumbs',
    TITLE_ALT: 'Breadcrumbs',
  },
  BUTTON_COMPONENT: {
    PATH: '/components/button-component',
    TITLE: 'Button',
    TITLE_ALT: 'Button',
  },
  CHIP_COMPONENT: {
    PATH: '/components/chip-component',
    TITLE: 'Chip',
    TITLE_ALT: 'Chip',
  },
  FORM_COMPONENT: {
    PATH: '/components/form-component',
    TITLE: 'Form',
    TITLE_ALT: 'Form',
  },
  ICON_COMPONENT: {
    PATH: '/components/icon-component',
    TITLE: 'Icon',
    TITLE_ALT: 'Icon',
  },
  MODAL_COMPONENT: {
    PATH: '/components/modal-component',
    TITLE: 'Modal',
    TITLE_ALT: 'Modal',
  },
  TABLE_COMPONENT: {
    PATH: '/components/table-component',
    TITLE: 'Table',
    TITLE_ALT: 'Table',
  },
  TOAST_COMPONENT: {
    PATH: '/components/toast-component',
    TITLE: 'Toast',
    TITLE_ALT: 'Toast',
  },
}

const ModulesComponentsRender = [
  {
    to: ModulesComponentScreens.ALERT_COMPONENT.PATH,
    activePath: ModulesComponentScreens.ALERT_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.ALERT_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.BREADCRUMB_COMPONENT.PATH,
    activePath: ModulesComponentScreens.BREADCRUMB_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.BREADCRUMB_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.BUTTON_COMPONENT.PATH,
    activePath: ModulesComponentScreens.BUTTON_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.BUTTON_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.CHIP_COMPONENT.PATH,
    activePath: ModulesComponentScreens.CHIP_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.CHIP_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.FORM_COMPONENT.PATH,
    activePath: ModulesComponentScreens.FORM_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.FORM_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.ICON_COMPONENT.PATH,
    activePath: ModulesComponentScreens.ICON_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.ICON_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.MODAL_COMPONENT.PATH,
    activePath: ModulesComponentScreens.MODAL_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.MODAL_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.TABLE_COMPONENT.PATH,
    activePath: ModulesComponentScreens.TABLE_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.TABLE_COMPONENT.TITLE,
  },
  {
    to: ModulesComponentScreens.TOAST_COMPONENT.PATH,
    activePath: ModulesComponentScreens.TOAST_COMPONENT.PATH,
    hasBullet: true,
    title: ModulesComponentScreens.TOAST_COMPONENT.TITLE,
  },
]

export {ModulesComponentScreens, ModulesComponentsRender}
