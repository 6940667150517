import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const HomeMenuScreens = {
  HOMEMENU: {
    PATH: '/home-menu',
    TITLE: 'Home Menu',
    TITLE_ALT: 'Home Menu',
    PERMISSION: 'home_menu.read',
  },
  LIST_HOME_MENU: {
    PATH: '/home-menu/list',
    HEADER_NAME: 'Home Menu',
    TITLE: 'Home Menu',
    TITLE_ALT: 'Home Menu',
    PERMISSION: 'home_menu.read',
  },
  ADD_HOME_MENU: {
    PATH: '/home-menu/list/add',
    TITLE: 'Add Home Menu',
    TITLE_ALT: 'Add Home Menu',
    PERMISSION: 'home_menu.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_HOME_MENU: {
    PATH: '/home-menu/list/detail/:id',
    TITLE: 'Detail Home Menu',
    TITLE_ALT: 'Detail Home Menu',
    PERMISSION: 'home_menu.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_HOME_MENU: {
    PATH: '/home-menu/list/edit/:id',
    TITLE: 'Edit Home Menu',
    TITLE_ALT: 'Edit Home Menu',
    PERMISSION: 'home_menu.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([HomeMenuScreens.LIST_HOME_MENU])

HomeMenuScreens.ADD_HOME_MENU.breadcrumbs = () => listBreadcrumbs
HomeMenuScreens.EDIT_HOME_MENU.breadcrumbs = () => listBreadcrumbs
HomeMenuScreens.DETAIL_HOME_MENU.breadcrumbs = () => listBreadcrumbs

export default HomeMenuScreens
