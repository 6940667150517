import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

const PointsEarningScreens = {
  POINTS_EARNING: {
    PATH: '/points-earning',
    TITLE: 'Points Earning',
    TITLE_ALT: 'Points Earning',
    breadcrumbs: defaultBreadcrumbs,
  },
  // EARNING AND RESERVE RATE
  LIST_EARNING_RESERVE: {
    PATH: '/points-earning/earning-reserve/list',
    HEADER_NAME: 'Earning and Reserve Rate',
    TITLE: 'Earning and Reserve Rate',
    TITLE_ALT: 'Earning & Reserve Rate',
    PERMISSION: 'earning_reserve_rate.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_EARNING_RESERVE: {
    PATH: '/points-earning/earning-reserve/list/edit/:id',
    TITLE: 'Edit Earning and Reserve Rate',
    TITLE_ALT: 'Edit Earning & Reserve Rate',
    PERMISSION: 'earning_reserve_rate.edit',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_EARNING_RESERVE: {
    PATH: '/points-earning/earning-reserve/list/detail/:id',
    TITLE: 'Earning and Reserve Rate Details',
    TITLE_ALT: 'Earning & Reserve Rate Details',
    PERMISSION: 'earning_reserve_rate.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // ADDITIONAL POINTS
  LIST_ADDITIONAL_POINTS: {
    PATH: '/points-earning/additional-points/list',
    HEADER_NAME: 'Additional Points',
    TITLE: 'Additional Points',
    TITLE_ALT: 'Additional Points',
    PERMISSION: 'additional_points.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_ADDITIONAL_POINTS: {
    PATH: '/points-earning/additional-points/list/add',
    TITLE: 'Add Additional Points',
    TITLE_ALT: 'Add Additional Points',
    PERMISSION: 'additional_points.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  // GRADUATION POINTS
  LIST_GRADUATION_POINTS: {
    PATH: '/points-earning/benefit/list',
    ACTIVE_PATH: '/points-earning/benefit',
    HEADER_NAME: 'Graduation Points',
    TITLE: 'Graduation Points',
    TITLE_ALT: 'Graduation Points',
    PERMISSION: 'graduation_points.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_GRADUATION_POINTS: {
    PATH: '/points-earning/benefit/list/add',
    TITLE: 'Add Graduation Points',
    TITLE_ALT: 'Add Graduation Points',
    PERMISSION: 'graduation_points.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_GRADUATION_POINTS: {
    PATH: '/points-earning/benefit/list/edit/:id',
    TITLE: 'Edit Graduation Points',
    TITLE_ALT: 'Edit Graduation Points',
    PERMISSION: 'graduation_points.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_GRADUATION_POINTS: {
    PATH: '/points-earning/benefit/list/detail/:id',
    TITLE: 'Graduation Points Details',
    TITLE_ALT: 'Graduation Points Details',
    PERMISSION: 'graduation_points.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  // EARNING CAMPAIGN RATE
  LIST_EARNING_CAMPAIGN: {
    PATH: '/points-earning/earning-campaign/list',
    HEADER_NAME: 'Earning Campaign',
    TITLE: 'Earning Campaign',
    TITLE_ALT: 'Earning Campaign',
    PERMISSION: 'earning_campaign.read',
    breadcrumbs: defaultBreadcrumbs,
  },
  ADD_EARNING_CAMPAIGN: {
    PATH: '/points-earning/earning-campaign/list/add',
    TITLE: 'Add Earning Campaign',
    TITLE_ALT: 'Add Earning Campaign',
    PERMISSION: 'earning_campaign.create',
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_EARNING_CAMPAIGN: {
    PATH: '/points-earning/earning-campaign/list/edit/:id',
    TITLE: 'Edit Earning Campaign',
    TITLE_ALT: 'Edit Earning Campaign',
    PERMISSION: 'earning_campaign.update',
    breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_EARNING_CAMPAIGN: {
    PATH: '/points-earning/earning-campaign/list/detail/:id',
    TITLE: 'Earning Campaign Details',
    TITLE_ALT: 'Earning Campaign Details',
    PERMISSION: 'earning_campaign.read',
    breadcrumbs: defaultBreadcrumbs,
  },
}

export default PointsEarningScreens
