import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import CaseManagementScreens from '../modules/case-management/Screens'
import DashboardScreens from '../modules/dashboard/Screens'
import HomeMenuScreens from '../modules/home-menu/Screens'
import ManageBannerScreens from '../modules/manage-banner/Screens'
import MarketingScreens from '../modules/marketing/Screens'
import MemberListScreens from '../modules/member-list/Screens'
import UserRoleScreens from '../modules/permissions/Screens'
import PointExpirationScreens from '../modules/point-expiration/Screens'
import PointsEarningScreens from '../modules/points-earning/Screens'
import PointsRedemptionScreens from '../modules/points-redemption/Screens'
import ProfileScreens from '../modules/profile/Screens'
import TierBenefitScreens from '../modules/tier-benefit/Screens'
import {ModulesComponentScreens} from '../modules_component/Screens'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const UserRolePage = lazy(() => import('../modules/permissions/UserRoleRoutes'))
  const HomeMenuPage = lazy(() => import('../modules/home-menu/HomeMenuRoutes'))
  const ManageBannerPage = lazy(() => import('../modules/manage-banner/ManageBannerRoutes'))
  const MarketingPage = lazy(() => import('../modules/marketing/MarketingRoutes'))
  const MemberPage = lazy(() => import('../modules/member-list/MemberListRoutes'))
  const PointsRedemptionPage = lazy(
    () => import('../modules/points-redemption/PointsRedemptionRoutes')
  )
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const PointsEarningPage = lazy(() => import('../modules/points-earning/PointsEarningRoutes'))
  const PointExpirationPage = lazy(
    () => import('../modules/point-expiration/PointExpirationRoutes')
  )
  const TierBenfitPage = lazy(() => import('../modules/tier-benefit/TierBenefitRoutes'))
  const CaseManagementPage = lazy(() => import('../modules/case-management/CaseManagementRoutes'))
  //modules_components
  const ModulesComponentsPage = lazy(() => import('../modules_component/ModulesComponentRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={UserRoleScreens.USER_ROLE.PATH} component={UserRolePage} />
          <Route path={HomeMenuScreens.HOMEMENU.PATH} component={HomeMenuPage} />
          <Route path={ManageBannerScreens.MANAGE_BANNER.PATH} component={ManageBannerPage} />
          <Route path={MarketingScreens.MARKETING.PATH} component={MarketingPage} />
          <Route path={MemberListScreens.MEMBER.PATH} component={MemberPage} />
          <Route
            path={PointsRedemptionScreens.POINT_REDEMPTION.PATH}
            component={PointsRedemptionPage}
          />
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Route path={PointsEarningScreens.POINTS_EARNING.PATH} component={PointsEarningPage} />
          <Route
            path={PointExpirationScreens.POINT_EXPIRATION.PATH}
            component={PointExpirationPage}
          />
          <Route path={TierBenefitScreens.TIER_BENEFIT.PATH} component={TierBenfitPage} />
          <Route path={CaseManagementScreens.CASE_MANAGEMENT.PATH} component={CaseManagementPage} />
          <Route
            path={ModulesComponentScreens.MODULES_COMPONENT.PATH}
            component={ModulesComponentsPage}
          />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
