import clsx from 'clsx'
import {FC, HTMLProps} from 'react'

export interface RadioOption {
  label?: string
  value?: string | number | boolean
}

export interface FormRadioProps extends HTMLProps<HTMLInputElement> {
  value?: string
  options?: RadioOption[]
  className?: string
  error?: string
  touched?: boolean
  checkedValue?: string | number
  changedValue?: any
}

const FormRadio: FC<FormRadioProps> = ({
  value,
  options,
  className = '',
  touched,
  error,
  checkedValue,
  changedValue,
  ...rest
}) => {
  return (
    <>
      <div
        data-testid='formradio-test'
        className={clsx('w-full md:flex md:space-x-6 py-2', className)}
      >
        {options?.map((option, index) => {
          if (option.value === undefined)
            return <div key={option.value || index} className='flex-1'></div>

          return (
            <div key={option.value as string} className='flex items-center flex-1 mb-4 md:mb-0'>
              <label className='flex items-center'>
                <input
                  type='radio'
                  checked={checkedValue === option.value}
                  onChange={() => changedValue(option.value)}
                  className='cursor-pointer'
                  {...rest}
                />
                <span className='ml-4 text-neutral-80 text-fs-9'>{option.label}</span>
              </label>
            </div>
          )
        })}
      </div>

      {touched && error && <div className='mt-2 font-medium text-danger text-fs-10'>{error}</div>}
    </>
  )
}

export {FormRadio}
