import {defaultBreadcrumbs, generateBreadcrumb} from 'src/app/models/screen-prop'

const MemberListScreens = {
  MEMBER: {
    PATH: '/member',
    TITLE: 'Member',
    TITLE_ALT: 'Member',
    PERMISSION: 'members.read',
  },
  LIST_MEMBER: {
    PATH: '/member/list',
    HEADER_NAME: 'Member List',
    TITLE: 'Member List',
    TITLE_ALT: 'Member List',
    PERMISSION: 'members.read',
  },
  ADD_MEMBER: {
    PATH: '/member/list/add',
    HEADER_NAME: 'Add Member',
    TITLE: 'Add Member',
    TITLE_ALT: 'Add Member',
    PERMISSION: 'members.create',
  },
  DETAIL_MEMBER: {
    PATH: '/member/list/detail/:id/:tab',
    TITLE: 'Detail Member',
    TITLE_ALT: 'Detail Member',
    PERMISSION: 'members.read',
    buildPath: (id: string, tab: string) => `/member/list/detail/${id}/${tab}`,
    breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_MEMBER: {
    PATH: '/member/list/edit/:id',
    TITLE: 'Edit Member',
    TITLE_ALT: 'Edit Member',
    PERMISSION: 'members.update',
    breadcrumbs: defaultBreadcrumbs,
  },
}

const listBreadcrumbs = generateBreadcrumb([MemberListScreens.LIST_MEMBER])

MemberListScreens.EDIT_MEMBER.breadcrumbs = () => listBreadcrumbs
MemberListScreens.DETAIL_MEMBER.breadcrumbs = () => listBreadcrumbs

export const DetailMemberTab = {
  SUMMARY: {
    PATH: '/member/list/detail/:id/summary',
    TITLE: 'Summary',
    TITLE_ALT: 'Summary',
    LABEL: 'Summary',
    SLUG: 'summary',
  },
  PROFILE_LOG: {
    PATH: '/member/list/detail/:id/profile-log',
    TITLE: 'Profile Log',
    TITLE_ALT: 'Profile Log',
    LABEL: 'Profile Log',
    SLUG: 'profile-log',
  },
  TRANSACTION_HISTORY: {
    PATH: '/member/list/detail/:id/transaction-history',
    TITLE: 'Transaction History',
    TITLE_ALT: 'Transaction History',
    LABEL: 'Transaction History',
    SLUG: 'transaction-history',
  },
  CHALLENGE_HISTORY: {
    PATH: '/member/list/detail/:id/challenge-history',
    TITLE: 'Challenge History',
    TITLE_ALT: 'Challenge History',
    LABEL: 'Challenge History',
    SLUG: 'challenge-history',
  },
}

export default MemberListScreens
