import axios, {CancelToken} from 'axios'
import {BaseResponse, Pagination, QueryModel, Response} from 'src/app/models/api.types'
import {Role, RoleAccessTemplate, RoleDetail} from '../models/Role.model'
import {User, UserQuery} from '../models/User.model'

// API URL
export const USER = '/api/v1/admins/users'
export const ROLE = '/api/v1/admins/user-roles'
export const ROLE_ACCESS_TEMPLATE = '/api/v1/admins/user-roles/access-template'

// REACT QUERY KEY
export const LIST_USER_KEY = 'list-user'
export const DETAIL_USER_KEY = 'detail-user'
export const LIST_ROLE_KEY = 'list-role'
export const DETAIL_ROLE_KEY = 'detail-role'
export const ACCESS_TEMPLATE = 'access-template'

// User API
export const getListUser = (params?: UserQuery, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Pagination<User[]>>>(USER, {
    params,
    cancelToken,
  })
}

export const getDetailUser = (id?: string, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/${id}`, {
    cancelToken,
  })
}

export const saveUser = (data?: User, id?: string, cancelToken?: CancelToken) => {
  if (id)
    return axios.put(`${USER}/${id}`, data, {
      cancelToken,
    })

  return axios.post(USER, data, {
    cancelToken,
  })
}

export const deleteUser = (id?: string, data?: any, cancelToken?: CancelToken) => {
  return axios.delete(`${USER}/${id}`, {
    cancelToken,
    data,
  })
}

export const verificationUserToken = (data?: any, cancelToken?: CancelToken) => {
  return axios.post(`${USER}/verification-token`, data, {
    cancelToken,
  })
}

export const resetPasswordUser = (data?: any, headers?: any) => {
  return axios.post(`${USER}/reset-password`, data, {
    headers,
  })
}

export const forgotPassword = (data?: any, cancelToken?: CancelToken, headers?: any) => {
  return axios.post(`${USER}/forgot-password`, data, {
    cancelToken,
    headers,
  })
}

export const getProfile = (cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/my-profile`, {cancelToken})
}

// Role API

export const getListRole = (params?: QueryModel, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Pagination<Role[]>>>(ROLE, {
    params,
    cancelToken,
  })
}

export const getDetailRole = (id?: string, cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<RoleDetail>>>(`${ROLE}/${id}`, {
    cancelToken,
  })
}

export const saveRole = (data?: Role, id?: string, cancelToken?: CancelToken) => {
  if (id)
    return axios.put(`${ROLE}/${id}`, data, {
      cancelToken,
    })

  return axios.post(ROLE, data, {
    cancelToken,
  })
}

export const deleteRole = (id?: string, cancelToken?: CancelToken) => {
  return axios.delete(`${ROLE}/${id}`, {
    cancelToken,
  })
}

export const getListAccessTemplateRole = (cancelToken?: CancelToken) => {
  return axios.get<BaseResponse<Response<RoleAccessTemplate[]>>>(ROLE_ACCESS_TEMPLATE, {
    cancelToken,
  })
}
